import React from 'react';
import Container from 'react-bootstrap/Container';
import { Card, Row } from 'react-bootstrap';

// eslint-disable-next-line import/no-named-as-default
import skillsData from '../media/skills-data';

export default function Skills() {
  return (
    <section id="skills" className="section">
      <Container className="content">
        <h3 className="orange-gradient">Skills</h3>
        <h4 className="mt-1 mb-4">Languages</h4>
        <Row>
          {skillsData.languages.map((data) => (
            <Card key={data.id} className="text-center border-0 bg-transparent" style={{ width: '11rem' }}>
              <Card.Img variant="top" src={data.image} alt={data.altText} className="svgicon" />
              <Card.Footer className="skills-text border-0 bg-transparent mt-1 mb-3">
                {data.name}
              </Card.Footer>
            </Card>
          ))}
        </Row>
        <h4 className="mt-5 mb-4">Libraries</h4>
        <Row>
          {skillsData.libraries.map((data) => (
            <Card key={data.id} className="text-center border-0 bg-transparent" style={{ width: '11rem' }}>
              <Card.Img variant="top" src={data.image} alt={data.altText} className="svgicon" />
              <Card.Footer className="skills-text border-0 bg-transparent mt-1 mb-3">
                {data.name}
              </Card.Footer>
            </Card>
          ))}
        </Row>
      </Container>
    </section>
  );
}
