import React from 'react';
import * as Scroll from 'react-scroll';

export default function Terminal() {
  return (
    <div id="terminal">
      <div id="bar">
        <div id="bar-buttons">
          <div id="button-red" />
          <div id="button-yellow" />
          <div id="button-green" />
        </div>
      </div>
      <div id="screen">
        <p className="command">pwd</p>
        <p className="response">~/Home</p>

        <p className="command">ls ~</p>
        <div className="response array">
          <Scroll.Link className="array-element" to="home" smooth="easeInOutQuad">&quot;Home&quot;</Scroll.Link>
          {', '}
          <Scroll.Link className="array-element" to="about" smooth="easeInOutQuad">&quot;About&quot;</Scroll.Link>
          {', '}
          <Scroll.Link className="array-element" to="skills" smooth="easeInOutQuad">&quot;Skills&quot;</Scroll.Link>
          {', '}
          <Scroll.Link className="array-element" to="projects" smooth="easeInOutQuad">&quot;Projects&quot;</Scroll.Link>
          {', '}
          <Scroll.Link className="array-element" to="contact" smooth="easeInOutQuad">&quot;Contact&quot;</Scroll.Link>
        </div>

        <div className="command">python3</div>

        <p className="command nested">import George</p>

        <p className="command nested">George.listPosition()</p>
        <p className="response">Full-Stack Software Engineer @ Northrop Grumman</p>

        <p className="command nested">George.listEducation()</p>
        <p className="response">Data Science - University of California, Berkeley</p>

        <p className="command nested">George.getLocation()</p>
        <p className="response">SF Bay Area, California</p>

        <p className="command nested">George.getResume()</p>
        <p className="response">
          <a href={`${process.env.PUBLIC_URL}/georgeliu-resume.pdf`} target="_blank" rel="noopener noreferrer">
            georgeliu-resume.pdf
          </a>
        </p>

        <p className="command nested">George.listLinks()</p>
        <p className="response array">
          <a className="array-element" href="https://linkedin.com/in/thegeorgeliu" target="_blank" rel="noopener noreferrer">
            &quot;LinkedIn&quot;
          </a>
          {', '}
          <a className="array-element" href="https://github.com/thegeorgeliu" target="_blank" rel="noopener noreferrer">
            &quot;Github&quot;
          </a>
          {', '}
          <a className="array-element" href="https://kaggle.com/thegeorgeliu" target="_blank" rel="noopener noreferrer">
            &quot;Kaggle&quot;
          </a>
          {', '}
          <a className="array-element" href="https://flickr.com/photos/thegeorgeliu" target="_blank" rel="noopener noreferrer">
            &quot;Flickr&quot;
          </a>
        </p>

        <p className="command nested">
          <span className="cursor">&nbsp;</span>
        </p>
      </div>
    </div>
  );
}
