/* eslint-disable react/no-array-index-key */
import React, { useState, useCallback } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import {
  Badge, Card, Button, Table,
} from 'react-bootstrap';

// eslint-disable-next-line import/no-named-as-default
import projectsData from '../media/projects-data';

export default function Projects() {
  const [isTableVisible, setIsTableVisible] = useState(false);

  const toggleTable = useCallback(() => {
    setIsTableVisible((wasTableVisible) => !wasTableVisible);
  }, []);

  function generateBadge(concept, key) {
    const badgeColors = new Map([
      ['Data Science', 'badge-purple'],
      ['Computer Science', 'badge-blue'],
      ['Software Engineering', 'badge-blue'],
      ['Electrical Engineering', 'badge-yellow'],
      ['Business', 'badge-red'],
      ['Machine Learning', 'badge-purple'],
      ['Artificial Intelligence', 'badge-purple'],
    ]);

    let color = 'badge-neutral';

    if (badgeColors.has(concept)) {
      color = badgeColors.get(concept);
    }

    return (
      <Badge bg="" className={`${color} me-1 mb-1`} key={key}>
        {String(concept)}
      </Badge>
    );
  }

  return (
    <section id="projects" className="section">
      <Container className="content">
        <h3 className="teal-gradient">Projects</h3>
        <Row>
          {projectsData.filter((proj) => proj.showcase).map((data, key) => (
            <Card className="mx-3 my-2 project-card" key={key}>
              <Card.Body>
                <Card.Title>{data.name}</Card.Title>
                <Card.Text>{data.description}</Card.Text>
              </Card.Body>
              <Card.Footer>
                {
                  data.concepts.map((concept, cKey) => (
                    generateBadge(concept, cKey)
                  ))
                }
                <Badge bg="primary">{data.concept}</Badge>
              </Card.Footer>
            </Card>
          ))}
        </Row>
        <div className="text-center mt-4">
          <Button variant="teal-gradient" size="lg" onClick={toggleTable}>
            {isTableVisible ? 'Show Less' : 'Show More'}
          </Button>
        </div>
        {isTableVisible && (
          <Table striped hover variant="dark" responsive="sm" className="mt-4">
            <thead>
              <tr>
                <th>Name</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              {projectsData.filter((proj) => !proj.showcase).map((data, key) => (
                <tr key={key}>
                  <td>
                    {data.name}
                    <div className="align-bottom">
                      {
                        data.concepts.map((concept, cKey) => (
                          generateBadge(concept, cKey)
                        ))
                      }
                    </div>
                  </td>
                  <td>{data.description}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Container>
    </section>
  );
}
