import React from 'react';
import Container from 'react-bootstrap/Container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import {
  faLinkedin, faGithub, faKaggle, faFlickr,
} from '@fortawesome/free-brands-svg-icons';

export default function Contact() {
  return (
    <section id="contact" className="section">
      <Container className="content">
        <h3 className="purple-gradient">Contact Me</h3>
        <p>
          Let&apos;s chat! I&apos;m always open to new opportunities, collaborations, or ideas.
          Feel free to reach out to me via email or on any of the platforms below.
        </p>
        <ul className="contact-links">
          <li>
            <FontAwesomeIcon icon={faEnvelope} size="xl" className="me-2" fixedWidth />
            <a href="mailto:george.t.liu@gmail.com">george.t.liu@gmail.com</a>
          </li>
          <li>
            <FontAwesomeIcon icon={faLinkedin} size="xl" className="me-2" fixedWidth />
            <a target="_blank" rel="noopener noreferrer" href="https://linkedin.com/in/thegeorgeliu">
              thegeorgeliu
            </a>
          </li>
          <li>
            <FontAwesomeIcon icon={faGithub} size="xl" className="me-2" fixedWidth />
            <a target="_blank" rel="noopener noreferrer" href="https://github.com/thegeorgeliu">
              thegeorgeliu
            </a>
          </li>
          <li>
            <FontAwesomeIcon icon={faKaggle} size="xl" className="me-2" fixedWidth />
            <a target="_blank" rel="noopener noreferrer" href="https://kaggle.com/thegeorgeliu">
              thegeorgeliu
            </a>
          </li>
          <li>
            <FontAwesomeIcon icon={faFlickr} size="xl" className="me-2" fixedWidth />
            <a target="_blank" rel="noopener noreferrer" href="https://flickr.com/photos/thegeorgeliu">
              thegeorgeliu
            </a>
          </li>
        </ul>
      </Container>
      <div className="footer-text">
        Made by George Liu &copy;&nbsp;
        {new Date().getFullYear()}
      </div>
    </section>
  );
}
