import pythonLogo from './python.svg';
import javaLogo from './java.svg';
import cSharpLogo from './cSharp.svg';
import cLogo from './c.svg';
import swiftLogo from './swift.svg';
import databaseLogo from './database.svg';
import reactLogo from './react.svg';
import pandasLogo from './pandas-mark.svg';
import scikitLogo from './scikit-learn-mark.svg';
import dotNetLogo from './dotNet.svg';
import swiftUILogo from './swiftUI.svg';
import railsLogo from './rails.svg';

export const skillsData = {
  languages: [
    {
      name: 'Python',
      image: pythonLogo,
      altText: 'Python logo',
      id: 0,
    },
    {
      name: 'Java',
      image: javaLogo,
      altText: 'Java logo',
      id: 1,
    },
    {
      name: 'C#',
      image: cSharpLogo,
      altText: 'C# logo',
      id: 2,
    },
    {
      name: 'C',
      image: cLogo,
      altText: 'C logo',
      id: 3,
    },
    {
      name: 'Swift',
      image: swiftLogo,
      altText: 'Swift logo',
      id: 4,
    },
    {
      name: 'SQL',
      image: databaseLogo,
      altText: 'SQL logo',
      id: 5,
    },
  ],
  libraries: [
    {
      name: 'React.js',
      image: reactLogo,
      altText: 'React logo',
      id: 6,
    },
    {
      name: 'pandas',
      image: pandasLogo,
      altText: 'pandas logo',
      id: 7,
    },
    {
      name: 'scikit-learn',
      image: scikitLogo,
      altText: 'scikit-learn logo',
      id: 8,
    },
    {
      name: '.NET',
      image: dotNetLogo,
      altText: '.NET logo',
      id: 9,
    },
    {
      name: 'SwiftUI',
      image: swiftUILogo,
      altText: 'SwiftUI logo',
      id: 10,
    },
    {
      name: 'Ruby on Rails',
      image: railsLogo,
      altText: 'Ruby on Rails logo',
      id: 11,
    },
  ],
};
export default skillsData;
