import React from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import * as Scroll from 'react-scroll';

export default function Navigation() {
  return (
    <Container>
      <Navbar fixed="top">
        <Container className="justify-content-center">
          <Nav variant="pills" className="nav-pills">
            <Nav.Link as={Scroll.Link} to="home" smooth="easeInOutQuad" spy>Home</Nav.Link>
            <Nav.Link as={Scroll.Link} to="about" smooth="easeInOutQuad" spy>About</Nav.Link>
            <Nav.Link as={Scroll.Link} to="skills" smooth="easeInOutQuad" spy>Skills</Nav.Link>
            <Nav.Link as={Scroll.Link} to="projects" smooth="easeInOutQuad" spy>Projects</Nav.Link>
            <Nav.Link as={Scroll.Link} to="contact" smooth="easeInOutQuad" spy>Contact</Nav.Link>
          </Nav>
        </Container>
      </Navbar>
    </Container>
  );
}
