import React from 'react';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';

import profilePicture from '../media/profile-picture.jpg';

export default function About() {
  function openResume() {
    window.open(`${process.env.PUBLIC_URL}/georgeliu-resume.pdf`);
  }

  return (
    <section id="about" className="section">
      <Container className="content">
        <h3 className="blue-gradient">About Me</h3>
        <p>
          Hey there! I&apos;m George, a data scientist and software engineer. I am passionate
          about finding solutions to problems, discovering patterns and insights
          from data, as well as conveying information to others effectively and creatively.
        </p>
        <img
          src={profilePicture}
          className="profile col-md-6 float-md-end mb-3 ms-md-3"
          alt="George at graduation"
        />
        <p className="mt-4">
          Although I graduated from the University of California, Berkeley with a degree in
          Data Science in December 2020, I haven’t stopped learning — whether that&apos;s reading
          about the latest technologies and developments in the field of data science,
          finding new or better ways to solve problems, or diversifying my skill set.
        </p>
        <p className="mt-4">
          Currently, I&apos;m working at Northrop Grumman as a full-stack software engineer,
          helping build a network management system with monitoring capabilities over
          the Wideband Global SATCOM system.
        </p>
        <p className="mt-4">
          In my free time, I also enjoy hiking, snowboarding, and landscape photography —
          visiting and exploring new environments continually challenges me to observe my
          surroundings and consider new perspectives.
        </p>
        <Button className="mt-4" variant="teal" size="lg" onClick={() => openResume()}>
          View Resume
        </Button>
      </Container>
    </section>
  );
}
