export const projectsData = [
  {
    name: 'Digit and Language Classification',
    concepts: ['Data Science', 'Machine Learning', 'Python'],
    description: 'Built and trained a neural network to classify handwritten digits from the MNIST dataset as well as a recurrent neural network to identify the language of a text passage from 6 possible languages.',
    showcase: true,
  },
  {
    name: 'Pacman AI',
    concepts: ['Data Science', 'Artificial Intelligence', 'Python'],
    description: 'Designed and implemented various search and artificial intelligence algorithms to design a system capable of winning Pacman games. AI algorithms include: reflex agent, minimax with alpha-beta pruning, expectimax, value iteration, q-learning, and Bayes\' Nets with Hidden Markov Models.',
    showcase: true,
  },
  {
    name: 'Cal Maps',
    concepts: ['Software Engineering', 'Java'],
    description: 'Developed a web mapping application inspired by Google Maps that displays the city of Berkeley. Utilized real-world data from the OpenStreetMap project. Supports zoom, route finding, turn-by-turn navigation, and estimated time of arrival. Utilized Dijkstra\'s and A* shortest path algorithms.',
    showcase: true,
  },
  {
    name: 'Habit Tracking Application',
    concepts: ['Software Engineering', 'Swift', 'SwiftUI'],
    description: 'Developed a multiplatform (iOS, watchOS, and macOS) habit tracking application in SwiftUI, incorporating a MVVM architectural paradigm. Implemented Core Data, CloudKit, animation, multi-touch gesture handling functionality.',
    showcase: true,
  },
  {
    name: 'Personal Portfolio Website',
    concepts: ['Software Engineering', 'React'],
    description: 'Designed and built this responsive personal portfolio website with React and hosted on GitHub Pages.',
    showcase: true,
  },
  {
    name: 'Performance Optimization/Convolutional Neural Networks',
    concepts: ['Data Science', 'Machine Learning', 'Software Engineering', 'Java'],
    description: 'Applied performance optimizations techniques (loop unrolling, SIMD instructions, OpenMP parallelization, etc.) to increase performance of an image classification neural network from 268 to 3964 classifications per second while maintaining a 96% accuracy.',
    showcase: true,
  },
  {
    name: 'ActionMap Website',
    concepts: ['Software Engineering', 'Ruby on Rails'],
    description: 'Designed and developed a website that allows the user to easily find information on local elected officials using Ruby on Rails. Collaborated and applied Agile principles in a team.',
    showcase: false,
  },
  {
    name: 'Ideal Zoom Breakout Rooms',
    concepts: ['Computer Science', 'Python'],
    description: 'Developed a solver for an NP-Hard problem that attempts place students into Zoom breakout rooms such that total happiness is maximized across all rooms while keeping stress below a threshold in each room.',
    showcase: false,
  },
  {
    name: 'Yelp Maps Rating Visualizer',
    concepts: ['Data Science', 'Python'],
    description: 'Modeled the Yelp academic dataset and created a visualization of restaurant ratings using machine learning. The visualization is a Voronoi diagram, where the map is segmented into shaded regions indicative of the predicted rating of the closest restaurant.',
    showcase: false,
  },
  {
    name: 'CPU Design',
    concepts: ['Software Engineering', 'Electrical Engineering', 'RISC-V'],
    description: 'Designed a 32-bit two-cycle processor based on RISC-V using logisim-evolution.',
    showcase: false,
  },
  {
    name: 'RISC-V Instruction Set Emulator',
    concepts: ['Software Engineering', 'RISC-V'],
    description: 'Created an emulator that is able to execute RISC-V instructions according to the RISC-V ISA. The two primary components are the disassembler, which translates the RISC-V machine code into human-readable assembly code, and the executor, which executes the actual machine code.',
    showcase: false,
  },
  {
    name: 'Chatroom',
    concepts: ['Software Engineering', 'C'],
    description: 'Constructed a chatroom application, involving both a server and a client, in C. Communication is implemented via TCP sockets using the C socket interface.',
    showcase: false,
  },
  {
    name: 'World Autogeneration',
    concepts: ['Software Engineering', 'Java'],
    description: 'Created an engine for generating explorable worlds. Large scale open design project that involved working with a partner and presentation of the end product. The worlds are randomly generated, and the user can control a player that interacts with the world.',
    showcase: false,
  },
  {
    name: 'Capsim',
    concepts: ['Business'],
    description: 'Led a team to win first place in a business simulation competition. Made decisions in various aspects of a company including finances, production, marketing, as well as research and development.',
    showcase: false,
  },
  {
    name: 'Imaging and Tomography',
    concepts: ['Electrical Engineering'],
    description: 'Designed and built a imaging system that uses ambient light sensors, mask matrices, and eigenanalysis concepts to captures images.',
    showcase: false,
  },
  {
    name: 'Touchscreens',
    concepts: ['Electrical Engineering'],
    description: 'Designed and built resistive and capacitive touchscreens.',
    showcase: false,
  },
  {
    name: 'GPS and Localization',
    concepts: ['Electrical Engineering'],
    description: 'Designed and built an "acoustic positioning system" that implements fundamental behavior of GPS satellites. Used speakers as broadcast becons and a microphone to receive the emitted signals. Determined position using cross-correlation and multilateration.',
    showcase: false,
  },
];
export default projectsData;
