import React from 'react';
import Container from 'react-bootstrap/Container';
import Typed from 'typed.js';
import Terminal from './Terminal';

export default function Header() {
  const typedElement = React.useRef(null);
  const typedInstance = React.useRef(null);

  React.useEffect(() => {
    const options = {
      strings: [
        'data scientist', 'software engineer', 'developer',
        'problem solver', 'designer', 'photographer', 'innovator',
      ],
      typeSpeed: 50,
      backSpeed: 50,
      backDelay: 1600,
      loop: true,
    };

    typedInstance.current = new Typed(typedElement.current, options);

    return () => {
      typedInstance.current.destroy();
    };
  }, []);

  return (
    <section id="home" className="section">
      <Container className="content">
        <h1>Hello World,</h1>
        <br />
        <h2>
          I&apos;m George Liu —&nbsp;
          <span ref={typedElement} />
        </h2>
        <Terminal />
      </Container>
    </section>
  );
}
